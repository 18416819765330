<template>

  <div class="row" ref="conferenceGalleryForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="title"
                           rules="required"
                           name="The Title"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Title"
                               name="title"
                               fou
                               v-model="formData.title">
                     </fg-input>
                   </ValidationProvider>
                   <div class="col-md-12 col-sm-12">
                     <div class="form-group">
                       <label>Gallery</label>
                       <prime-uploader
                         :preview-width="'200px'"
                         :preview-height="'200px'"
                         :elementNum="20"
                         v-model="formData.images"
                       >
                       </prime-uploader>
                     </div>
                   </div>


                 </div>
               </div>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/conference-gallery/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        images: [],

      },
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  created(){
      const path = window.location.href;
      this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.conferenceGalleryForm
    });
    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Gallery";
      this.getConferenceGallery();
    } else {
      this.editMode = false;
      this.formTitle = "Add Gallery";
      this.loader.hide();
    }
  },


  methods: {
    getConferenceGallery() {
      let data = {
        'id' : this.id,
        'lang' : this.formData.lang
      };
      this.axios.post("conference-gallery/get",data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Gallery Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = this.formData;
      if (this.editMode === true) {
        request = this.axios.put("conference-gallery/update/" + this.id, formData);
        successMessage = "Gallery Updated Successfully";
      } else {
        request = this.axios.post("conference-gallery/create", formData);
        successMessage = "Gallery Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/conference-gallery/list/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },

    getBannerVideoInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_video"]) {
        return this.mediaInfo["panel_video"];
      } else {
        return "";
      }
    }

  }
}
</script>
